<template>
  <v-container class="py-6">
      <v-row>
        <v-col cols="12">
            <div class="box-wrapper">
                <div class="box-overlay" :class="{'open': isSidebar}"  @click="isSidebar = !isSidebar"></div>
                <div class="box-sidebar pb-3 shadow-sm" :class="{'open': isSidebar}">
                   <DashbordSidebar />
                </div>
                <div class="box-content">
                    <div class="d-flex justify-end pa-2 d-block d-md-none">
                        <v-btn
                            icon   
                            @click="isSidebar = !isSidebar"
                        >
                            <v-icon dark>
                                mdi-format-list-bulleted-square
                            </v-icon>
                        </v-btn>
                    </div>
                    <div class="box-container">
                       <div class="d-flex justify-space-between flex-wrap mb-5">
                           <div class="d-flex align-center ">
                                <img v-svg-inline class="icon mr-3 primary--text" src="@/assets/images/icons/heart_filled.svg" alt="" />
                                <h2 class="mb-0">My Wish List</h2>
                            </div>
                            <v-btn 
                                color="primary"
                                outlined
                                class="text-capitalize font-600"
                            >
                                Add All to Cart
                            </v-btn>
                       </div>
                      
                       
                        <v-row>
                            <v-col cols="12">
                                <v-data-iterator
                                    :items="items"
                                    :items-per-page.sync="itemsPerPage"
                                    :page.sync="page"
                                    hide-default-footer
                                >
                                    

                                    <template v-slot:default="props">
                                    <v-row>
                                        <v-col
                                        v-for="item in props.items"
                                        :key="item.name"
                                        cols="12"
                                        sm="6"
                                        md="6"
                                        lg="4"
                                        xl="3"
                                        
                                        >
                                        <CardCart :content-img="item.img" />
                                        </v-col>
                                    </v-row>
                                    </template>

                                    <template v-slot:footer>
                                    <v-row
                                        class="my-5 me-1"
                                        align="center"
                                        justify="center"
                                    >
                                        

                                        <v-spacer></v-spacer>

                                        <span
                                        class="mr-4
                                        grey--text"
                                        >
                                        Page {{ page }} of {{ numberOfPages }}
                                        </span>
                                        <v-btn
                                        fab
                                        :disabled="page == 1"
                                        small
                                        color="primary"
                                        class="mr-1"
                                        
                                        @click="formerPage"
                                        >
                                        <v-icon>mdi-chevron-left</v-icon>
                                        </v-btn>
                                        <v-btn
                                        fab
                                        
                                        :disabled="page == numberOfPages"
                                        small
                                        color="primary"
                                        class="ml-1"
                                        @click="nextPage"
                                        >
                                        <v-icon>mdi-chevron-right</v-icon>
                                        </v-btn>
                                    </v-row>
                                    </template>
                                </v-data-iterator>
                            </v-col>
                          </v-row>

                      
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashbordSidebar from "@/components/DashboardSidebar.vue";
import CardCart from "@/components/cartCard/CardCart";
export default {
    components: {
        DashbordSidebar,
        CardCart
    },
    data() {
        return{
            isSidebar: false,
            page: 1,
            itemsPerPage: 8,
            items: [
                {
                img: require('@/assets/images/products/Fashion/Accessories/7.PoliceGrayEyeglasses.png'),
                },
                {
                img: require('@/assets/images/products/Fashion/Accessories/8.RayBanMattBlack.png'),
                },
                {
                img: require('@/assets/images/products/Fashion/Accessories/9.RayBanBlack.png'),
                },
                {
                img: require('@/assets/images/products/Fashion/Accessories/10.RayBanOcean.png'),
                },
                {
                img: require('@/assets/images/products/Fashion/Accessories/11.SunglassesCollection.png'),
                },
                {
                img: require('@/assets/images/products/Fashion/Accessories/13.FossilWatchBrown.png'),
                },
                {
                img: require('@/assets/images/products/Fashion/Accessories/16.SkmeiWatchBlack.png'),
                },
                {
                img: require('@/assets/images/products/Fashion/Accessories/18.DragonRedWristWatch.png'),
                },
                {
                img: require('@/assets/images/products/Automotive/1.Ford2019.png'),
                title: 'Lord 2019'
                },
                {
                img: require('@/assets/images/products/Automotive/2.Audi2017.png'),
                title: 'Budi 2017'
                },
                {
                img: require('@/assets/images/products/Automotive/3.Tesla2015.png'),
                title: 'Fesla 2015'
                },
                {
                img: require('@/assets/images/products/Automotive/4.Porsche2018.png'),
                title: 'Porsche 2018'
                },
                {
                img: require('@/assets/images/products/Automotive/5.Ford2018.png'),
                title: 'Ford 2018'
                },
                {
                img: require('@/assets/images/products/Automotive/6.Ford2020.png'),
                title: 'Ford 2020'
                },
                {
                img: require('@/assets/images/products/Automotive/7.Mitsubishi2018.png'),
                title: 'Mitsubishi 2018'
                },
                {
                img: require('@/assets/images/products/Automotive/8.BMW2019.png'),
                title: 'BMW 2019'
                },
                
            ],
            range: [0, 100, 500, 1000],
            itemsTwo: ['Low to High', 'High to Low', 'Date'],
            
            
        }
    },
    computed: {
      numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      }, 
    },
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        nextPage () {
            if (this.page + 1 <= this.numberOfPages) this.page += 1
        },
        formerPage () {
            if (this.page - 1 >= 1) this.page -= 1
        },
        season (val) {
            return this.range[val]
        },
    }
       
}
</script>
